import AOS from './aos.js';
import SVGInjector from './svg-injector.min.js';

const handleMenu = () => {
  const menuContainer = document.querySelector('.menu');
  const openMenuButton = document.querySelector('.header__hamburger');
  const closeMenuButton = document.querySelector('.menu__header__hamburger');

  openMenuButton.addEventListener('click', () => {
    menuContainer.classList.toggle('visible');
  });

  closeMenuButton.addEventListener('click', () => {
    menuContainer.classList.toggle('visible');
  });
};

const simpleParallax = (elem, modifier = 5) => {
  let paras = document.getElementsByClassName(elem);

  if (!paras) {
    return;
  }

  for (let i = 0; i < paras.length; i++) {
    paras[i].setAttribute(
      'style',
      'background-repeat: no-repeat; background-attachment: fixed; background-size: cover;background-position: center center;'
    );
  }
  const sp = () => {
    for (let i = 0; i < paras.length; i++) {
      let x = paras[i].getBoundingClientRect().top / modifier;
      let y = Math.round(x * 100) / 100;
      paras[i].style.backgroundPosition = 'center ' + y + 'px';
    }
    requestAnimationFrame(sp);
  };
  requestAnimationFrame(sp);
};

const initChangeBackgroundPositionOnScroll = () => {
  document.addEventListener('DOMContentLoaded', function () {
    const container = document.querySelector('.scrolling-container');

    if (!container) {
      return;
    }

    let initialTop = window.pageYOffset || document.documentElement.scrollTop;
    let initialBgPositionY =
      parseInt(getComputedStyle(container).backgroundPositionY) || 0;

    window.addEventListener('scroll', function () {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      const scrollDifference = currentScrollTop - initialTop;

      const newBgPositionY = initialBgPositionY + scrollDifference / 4;
      container.style.backgroundPositionY = `${newBgPositionY}px`;
    });
  });
};

handleMenu();
const mySVGsToInject = document.querySelectorAll('img.svg-injection');
SVGInjector(mySVGsToInject);
initChangeBackgroundPositionOnScroll();
simpleParallax('parallax', 5);
AOS.init({
  offset: 100,
  duration: 500,
});
